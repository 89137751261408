import React from "react";

import { Button } from "components";
import AppConnectMasqScene from "assets/images/app-connect-masq.png";
import CircleDownIcon from "assets/icons/circle-down.svg";
import "./tokenomic.section.scss";

const platform = require("platform");

const Index = () => {
  const handleDownload = () => {};

  return (
    <div className="Token_tokenomic">
      <div className="container">
        <div className="row align-items-stretch text-center text-sm-start">
          <div className="col-xs-12 col-md-4 left-fluid">
            <img src={AppConnectMasqScene} alt="" />
          </div>
          <div className="col-xs-12 col-md-8 d-flex flex-column justify-content-center align-items-center align-items-sm-start ps-sm-5 py-5">
            <div
              className="label mb-4"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              MASQ PROTOCOL TOKENOMICS ARE EVOLVING
            </div>
            <div
              className="title mb-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              MASQ Utility Expands Through The Ecosystem
            </div>
            <div
              className="subtitle mb-4"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              MASQ Network is currently deployed and operating on the Polygon and Ethereum networks, and the Rinkeby and
              Mumbai testnets. More information will be released about expanded token utility and exactly how services are calculated between network users. Ultimately the model will become a free market for users to set their own peer rates for bandwidth services!
              <br />
              <br />
              With further monetisation model dynamics, it can also be possible for tokens to be burned with every transaction or even diverted to 
              open source developers in the MASQ ecosystem, to roll out further features and enhancements! This will engaged by ecosystem governance in the future.
              <br />
              <br />
              Note, you may earn less MASQ per MB than you spend to consume per MB for data.
            </div>
            <div
              className="badge"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="600"
              data-sal-easing="ease"
            >
              COMING SOON
            </div>
          </div>
        </div>
      </div>
      <div className="container secure-padding">
        <div className="row">
          <div className="col-xs-12">
            {/* <div
              className="secure text-center text-sm-start align-items-center align-items-sm-start"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <h1>Secure yourself online & Run a node today!</h1>
              <a
                className="mt-3"
                target="_blank"
                rel="noreferrer"
                // href={`/download/${window.platform.os.family.toLocaleLowerCase()}`}
                href={`/download/${platform.os.family.toLocaleLowerCase()}`}
              >
                <Button
                  preIcon={CircleDownIcon}
                  label="DOWNLOAD MASQ DVPN BROWSER"
                  handleClick={handleDownload}
                />
              </a>
              <div className="tip mt-3">
                {/* For {window.platform.os.toString()} or later. 
                For {platform.os.toString()} or later.
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
